<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
        <template v-slot:title>
          <span>
            <span>Фильтр</span>
            <el-button class="ml-5"
                plain
                size="mini"
                type="default" 
                @click.stop="clearFilters" 
                style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </span>
        </template>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="9" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="9" class="ml-0 filter--container">Группа учёта:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отображать объекты локальной НСИ:</el-col>
      </el-row>

      <el-row class="m-0 ml-2"  :gutter="5">
        <el-col :span="9" >
          <remote-search-input
            id="energy_objects-filter--name_input"
            v-model="filterModel.nameFilter"
            searchUrl="/api/psrType/SearchByName"
          />
        </el-col>

        <el-col :span="9">
          <el-select multiple filterable clearable size="small"
                     ref="registrationGroupSelector"
                     v-model="filterModel.registrationGroupsFilter" 
                     :collapse-tags="filterModel.registrationGroupsFilter.length > 5">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.registrationGroupsFilter = getSelectedIdsByName(registrationGroupsWithNotMfu, $refs.registrationGroupSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in registrationGroupsWithNotMfu"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>

        <el-col :span="6" >
          <el-switch style="width: 100%;"
            v-model="filterModel.isLocalNsiFilter"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="filter--container ml-2 ">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import { mapGetters } from 'vuex';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'EnergyObjectsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  mixins: [filterHelper],
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
    };
  },
  created() {
    if (UserSettingsService.has('psrTypeFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
  },
  computed: {
    ...mapGetters('identity', ['user']),
    ...mapGetters('dictionaries', ['registrationGroupsWithNotMfu'])
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('psrTypeFilter_filter_open', true, this.user.id);
        this.$emit('active-change', true);
      } else {
        UserSettingsService.delete('psrTypeFilter_filter_open', this.user.id);
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    }
  }
};
</script>

<style scoped>

</style>
