import axios from 'axios';

export default {
  getPsrTypesWithPagination(params) {
    return axios.post('/api/PsrType/GetPsrTypesWithPagination', params);
  },
  addPsrType: (psrType) => axios.post('/api/PsrType', psrType),
  updatePsrType: (psrType) => axios.put(`/api/PsrType/${psrType.id}`, psrType),
  deletePsrType: (id) => axios.delete(`/api/PsrType/${id}`),
  mergePsrType(mainId, secondaryIds) {
    return axios.post('/api/PsrType/Merge', { mainId, secondaryIds });
  },
};

